export function initDownloads() {
    /** @type {NodeListOf<HTMLLIElement>} */
    const downloads = document.querySelectorAll('.download-file');
    for (const download of downloads) {
        /** @type {HTMLButtonElement|null} */
        const infoButton = download.querySelector('.download-file-button--info');
        if (!infoButton) {
            continue;
        }

        /** @type {HTMLDialogElement} */
        const dialog = download.querySelector('.download-file-infos');
        /** @type {HTMLButtonElement} */
        const closeButton = dialog.querySelector('.download-file-infos-close');

        infoButton.addEventListener('click', () => {
            dialog.showModal();
        });

        closeButton.addEventListener('click', () => {
            dialog.close();
        });
    }
}